<template>
    <div class="container">
      <form>
        <div class="row">
            <div class="col-sm-6">
                <multiselect
                prepend="Discount Type:"
                v-model="discount_type"
                :options="discount_types"
                :multiple="false"
                :editable="true"
                class=""
                >
                </multiselect>
            </div>
            <div class="col-sm-6">
                <b-input-group prepend="Discount Amount:" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-form-input id="inline-form-input-username" type="number" v-model="discount_amount"></b-form-input>
                </b-input-group>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <b-input-group prepend="Redeemed:" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-form-input id="inline-form-input-username" type="number" v-model="reedemed" :disabled="true"></b-form-input>
                </b-input-group>
            </div>
            <div class="col-sm-6 bg-light">
                <p class="d-inline pr-2">Available:</p>
                <br/>
                <p class="d-inline"><b>Redeemed Amount:0.00</b></p>
            </div>
        </div>
        <button type="button" class="btn btn-primary mt-2" @click="emitDiscount()">Update</button>
      </form>
    </div>
  </template>
  
  <script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

  export default {
    data() {
      return {
        discount_type:'Percentage',
        discount_types:['Fixed','Percentage'],
        discount_amount:0.00,
        reedemed:0.00,
      };
    },
    components:{
        Multiselect,
    },
    mounted(){
    },
    methods: {
        emitDiscount() {
        if(this.discount_amount===0){
          alert('Discount amount is 0.00?')
          return
        }
        this.$emit('get-discount',{type:this.discount_type,amount:this.discount_amount});
        this.$root.$bvModal.hide('order-discount');
      }
    }
  }
  </script>
  
  <style>
  /* Add custom styles here */
  </style>
  