<template>
    <div class="container">
      <h2>Order Tax</h2>
      <form>
        <div class="form-group">
            <multiselect
              v-model="tax"
              :options="orderTaxes"
              :option-label="(tax) => tax.tax_name"
              placeholder="Select"
              label="tax_name"
              track-by="id"
              :multiple="false"
              :editable="true"
            >
            </multiselect>
        </div>
        <button type="button" class="btn btn-primary mt-2" @click="emitTax()">Update</button>
      </form>
    </div>
  </template>
  
  <script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import axios from "axios";

  export default {
    data() {
      return {
        tax:null,
        orderTaxes:[],
      };
    },
    components:{
        Multiselect,
    },
    mounted(){
      this.fetchTaxes();
    },
    methods: {
      fetchTaxes(){
        axios.get(window.$http+'tax-rates',{headers:window.$headers})
        .then((response)=>{
          this.orderTaxes=response.data['results']
        })
      },
      emitTax() {
        if(this.tax===null){
          alert('Please select a tax')
          return
        }
        console.log(this.tax.percentage)
        this.$emit('get-tax',this.tax.percentage);
        this.$root.$bvModal.hide('order-tax');
      }
    }
  }
  </script>
  
  <style>
  /* Add custom styles here */
  </style>
  