<template>
    <div class="container">
      <form>
        <div class="row">
            <div class="col-sm-12">
                <b-input-group prepend="Packing Charge:" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-form-input id="" type="number" v-model="service_charge"></b-form-input>
                </b-input-group>
            </div>
        </div>
        <button type="button" class="btn btn-primary mt-2" @click="emitServiceCharge()">Update</button>
      </form>
    </div>
  </template>
  
  <script>
//import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

  export default {
    data() {
      return {
        service_charge:0.00,
      };
    },
    components:{
        //Multiselect,
    },
    mounted(){
    },
    methods: {
        emitServiceCharge() {
        if(this.service_charge===0){
          alert('Service Charge cannot be 0.00?')
          return
        }
        this.$emit('get-service-charge',Number(this.service_charge));
        this.$root.$bvModal.hide('order-service-charge');
      }
    }
  }
  </script>
  
  <style>
  /* Add custom styles here */
  </style>
  